<template>
  <div id="home">
    <articles>
      <banner />
    </articles>

    <about />

    <subscribe />

    <!-- <social /> -->
  </div>
</template>

<script>
export default {
  name: "Home",

  components: {
    About: () => import("@/components/home/About"),
    Articles: () => import("@/components/home/Articles"),
    Banner: () => import("@/components/home/Banner"),
    // Social: () => import('@/components/home/Social'),
    Subscribe: () => import("@/components/home/Subscribe"),
  },
  mounted: function () {
    this.whatsme();
  },
  methods: {
    whatsme() {
      var options = {
        facebook: "110984737745221", // Facebook page ID
        whatsapp: "529982755023", // WhatsApp number
        call_to_action: "How may I help you?", // Call to action
        position: "right", // Position may be 'right' or 'left'

        button_color: "#008000", // Color of button
        order: "facebook,whatsapp", // Order of buttons
      };
      var proto = document.location.protocol,
        host = "getbutton.io",
        url = proto + "//static." + host;
      var s = document.createElement("script");
      s.type = "text/javascript";
      s.async = true;
      s.src = url + "/widget-send-button/js/init.js";
      s.onload = function () {
        WhWidgetSendButton.init(host, proto, options);
      };
      var x = document.getElementsByTagName("script")[0];
      x.parentNode.insertBefore(s, x);
    },
  },
};
</script>
